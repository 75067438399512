import {useUserStore} from '~/stores/user'

export const needsCegepFlag = () => {
  const userStore = useUserStore()

  return userStore.userData.institution?.country === 'CA' && userStore.userData.institution.isHigher
}

export const getCegepFlagHtml = () => {
  return needsCegepFlag() ? '<span class="opacity-50">+ CEGEP</span>' : null
}